import type { COUNTRY_SHORTS } from '~/constants'

export default function useInsurancePosition(country?: COUNTRY_SHORTS) {
  const { isWebView, mobileCapabilities } = usePlatform()
  const { $experiment } = useNuxtApp()

  /**
   * Check if the insurance form should be positioned at the payment step.
   * Always true for webview, and only true for US on the web.
   */
  const isInsurancePositionedAtPayment = computed(() => {
    if (isWebView.value) {
      return mobileCapabilities.value.canShowProtectionPlansOnPayment
    }

    if (country === 'CA') {
      return $experiment.getVariant('rvz22058ProtectionPlanExperimentCanada') === 'variant'
    }

    return country === 'US'
  })

  return {
    isInsurancePositionedAtPayment,
  }
}
